import * as React from 'react';
import { useFormik } from 'formik';
import * as styles from './styles/forms.module.css';

export default function SDForm() {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      ask: '',
    },
    onSubmit: (values) => {
      const formbody = { name: values.name, email: values.email, ask: values.ask };
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formbody),
      };
      fetch('https://api.silentdistortion.com/api', options)
        .then(() => {
          alert('Submission complete.  We will get back to you shortly.');
          formik.resetForm();
        })
        .catch((err) => console.error(err));
    },
  });

  return (
    <div className={styles.formmain}>
      <h4 className={styles.instructions}>
        Submit your request here.  We will get back to you soon.
      </h4>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <label htmlFor="name">
          Name:
        </label>
        <input type="text" id="name" name="name" className={styles.smallbox} onChange={formik.handleChange} value={formik.values.name} />
        <label htmlFor="email">
          Email:
        </label>
        <input type="email" id="email" name="email" className={styles.smallbox} onChange={formik.handleChange} value={formik.values.email} />
        <label htmlFor="ask">
          Request:
        </label>
        <textarea type="text" id="ask" name="ask" className={styles.largebox} onChange={formik.handleChange} value={formik.values.ask} />
        <button className="submitButton" type="submit" disabled={formik.isSubmitting}>Submit</button>
      </form>
    </div>
  );
}
