import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Picblock from '../components/picblock';
import SDForm from '../components/form';
import * as styles from '../components/styles/contact.module.css';

export default function SDContact({ data }) {
  const picdata = data.allMarkdownRemark.nodes;

  return (
    <Layout>
      <Seo title="Contact Us" />
      <div>
        { picdata.map((data) => (
          <Picblock data={data} key={data.id} />
        ))}
      </div>

      <SDForm>testing</SDForm>
    </Layout>
  );
}

export const query = graphql`
query contactpage {
    allMarkdownRemark(
      sort: {fields: frontmatter___sortorder, order: ASC}
      filter: {frontmatter: {page: {eq: "contact"}}}
    ) {
      nodes {
        id
        frontmatter {
          page
          pictitle
          sortorder
          title
          imageside
          imageurl {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
        }
        html
      }
    }
  }
  
`;

// TODO:  You can publish a test website with surge.sh.
